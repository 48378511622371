@charset "utf-8";
$primary-color-1: #197bbb !default;
$primary-color-2: #1397e1 !default;
$primary-color-3: #00b6da !default;
$primary-color-4: #7bd7de !default;
$secondary-color-1: #595959 !default;
$secondary-color-2: #808080 !default;
$secondary-color-3: #d9d9d9 !default;
$secondary-color-4: #f2f2f2 !default;
$basic-black: #000000 !default;
$basic-white: #ffffff !default;
$background-page: #f5f5f5;
$background: #1c2334 !default;
$highlight-color: #de4617 !default;
$feedback-info: #c8edff !default;
$feedback-success: #caeec3 !default;
$feedback-warning: #f2dcba !default;
$feedback-error: #edc9c9 !default;
$spacer: 1rem;
$o8-spacer: 30px;
.o8-gutter {
  padding-left: $o8-spacer;
  padding-right: $o8-spacer;
}

.disable-link-style,
.disable-link-style:hover,
.disable-link-style:focus,
.disable-link-style:active,
.disable-link-style:visited {
  text-decoration: none;
  color: inherit;
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: inherit;
  }
}



.o8-theme {
  background-color: $background-page;
  font-family: "Muli", sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  h1 {
    font-size: 32px;
    font-weight: 700;
    color: $primary-color-1;
  }
  h2 {
    font-size: 24px;
    font-weight: 600;
  }
  h3 {
    font-size: 24px;
    font-weight: 600;
  }
  h4 {
    font-size: 18px;
    font-weight: 700;
  }
  h5 {
    font-size: 16px;
    font-weight: 700;
  }
  h6 {
    font-size: 12px;
    font-weight: 700;
  }
}

h1.lead-font {
  margin-bottom: 28px;
}

.content-page-h1 {
  color: $basic-black !important;
}

.fa-hero {
  color: $primary-color-2 !important;
  font-size: 36px;
}

.pd-20 {
  padding: 20px !important;
}
.ml-60 {
  margin-left: 60px !important;
}

h2.o8-home-heading-2 {
  font-size: 16px;
  color: inherit;
  font-weight: 400;
  line-height: 24px;
}

p.o8-home-heading-3 {
  font-size: 24px;
  font-weight: 600;
  line-break: 28.8px;
  margin-bottom: 8px;
}

p.lead-font,
h2.lead-font {
  color: $primary-color-1;
  font-weight: bold;
  font-size: 28px;
  line-height: 38px;
}

h2.header-alt {
  color: $basic-white;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 20px;
  width: 180px;
  margin: 0 auto 20px auto;
}

.flex-even {
  flex: 1;
}

main.page-content {
  padding: 0;
}

main.page-content,
footer.site-footer {
  border: none;
}

.no-padding {
  padding: 0;
}

.image-blend {
  mix-blend-mode: multiply;
}

form {
  margin-left: 80px;
}

//ie11 Fixes
body.o8-theme {
  display: block !important;
}

//Controls
.o8-theme a.o8-primary-btn {
  background-color: $primary-color-2;
  font-weight: 700;
  width: 300px;
  padding: 10px;
  border-radius: 2px;
  color: white;
  &.o8-half-btn {
    width: 150px;
  }
  &:hover {
    background-color: $primary-color-1;
  }
}

.o8-theme .o8-primary-btn {
  background-color: $primary-color-2;
  font-weight: 700;
  width: 300px;
  padding: 10px;
  border-radius: 2px;
  color: white;
  &.o8-half-btn {
    width: 50%;
  }
  &:hover {
    background-color: $primary-color-1;
  }
}

.o8-theme a.o8-button-small {
  width: auto;
  min-width: 100px;
}

.o8-theme input.o8-input {
  height: 44px;
}

.o8-theme input.o8-input,
.o8-theme textarea.o8-input {
  min-height: 44px;
  width: 300px;
  border-radius: 2px;
  border: 1px solid $secondary-color-2;
  padding: 5px 10px;
  &:focus {
    border: 2px solid $basic-black;
  }
  &.o8-input-error {
    border-color: $highlight-color;
  }
}

label.error {
  color: $highlight-color !important;
}

.o8-pagination {
  .page-item {
    border-radius: 25px;
    background: transparent;
  }
  .page-link {
    border-radius: 25px;
    margin: 0 5px;
    border: 0px solid transparent;
    background-color: $secondary-color-4;
  }
}

.o8-theme .o8-pagination .page-link:hover {
  background-color: $primary-color-2;
  color: $basic-white;
}

.o8-theme .o8-pagination .page-item:first-child .page-link,
.o8-theme .o8-pagination .page-item:last-child .page-link {
  margin: 0 5px;
  border-radius: 25px;
}

.o8-breadcrumb i {
  margin: 0 5px;
}

//header
.site-header {
  padding: 40px 0 0 0;
  border: none;
  .navbar {
    .navbar-brand {
      padding: 0;
    }
    .navbar-nav {
      margin-top: 35px;
    }
    .navbar-collapse {
      align-self: end;
    }
    a.nav-link {
      font-size: 18px;
      font-weight: bold;
      color: $basic-black;
      border: none;
      border-bottom: 2px solid transparent;
      transition: border 0.5s ease, padding 0.5s ease;
      &:hover {
        color: $primary-color-1;
        border-bottom: 2px solid $primary-color-1;
      }
    }
  }
  .navbar > .navbar-collapse.collapsing a.nav-link,
  .navbar > .navbar-collapse.show a.nav-link {
    border-bottom: none;
    border-left: 2px solid transparent;
    &:hover {
      border-left: 2px solid $primary-color-1;
      padding-left: 5px;
    }
  }
}

.site-nav {
  line-height: 0;
}

.site-title {
  padding: 0;
  margin: 10px 0;
}

.site-title div {
  height: 75px;
  width: 300px;
  background: url(/assets/img/orbital-8-brand.png);
  text-indent: -9999em;
  padding: 0;
  margin-bottom: 0;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}

.o8-lead-img {
  margin-top: 7px;
  margin-bottom: 12px;
}

//section
.o8-page-section {
  min-height: 400px;
  justify-content: center;
  padding: 55px 0;
  margin: 50px 0;
  &.o8-page-section-small-top {
    padding: 25px 0 50px 0;
    margin: 25px 0 50px 0;
    min-height: 250px;
  }
  &.o8-header-comp-top {
    margin-top: 65px; //25px + 40px from header
  }
  .o8-contact-details {
    font-size: 18px !important;
  }
}

.o8-page-section-img-row .row .col-sm {
  padding-top: 15px;
  padding-bottom: 15px;
}

.o8-page-section-small {
  min-height: 200px;
  padding: 0;
  .col-sm {
    padding-top: 25px;
  }
  &.o8-page-section-small-top {
    padding: 10px 0 25px 0;
  }
}

.o8-page-section-xsmall {
  min-height: 80px;
  padding: 0;
  .col-sm {
    padding-top: 25px;
  }
  &.o8-page-section-small-top {
    padding: 10px 0 25px 0;
  }
}

.o8-page-section-large {
  min-height: 500px;
  padding: 50px 0 100px 0;
  margin: 50px 0;
}

.o8-page-section-alt {
  background-color: $background;
  justify-content: start;
  min-height: 550px;
  * {
    color: white;
  }
}

.o8-section-half-container {
  width: 50%;
}

.o8-page-section .o8-home-branding-text {
  width: 300px;
  padding: 36px 0;
}

.o8-list {
  color: $primary-color-1;
  font-weight: 600;
}

.o8-page-section .o8-branding-text {
  padding: 75px 0;
}

.o8-section-half-container.o8-branding-text {
  padding-left: 15px;
  padding-right: 15px;
}

.o8-page-section {
  &.o8-home-section-1 {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  &.o8-home-section-2 {
    min-height: 540px;
  }
  &.o8-home-section-3 {
    padding: 25px 0;
  }
  &.o8-home-section-4 {
    padding-top: 10px;
    margin-top: 0;
  }
  &.o8-blog-section-2 {
    min-height: 300px;
  }
  &.o8-blog-section-3 {
    min-height: 150px;
    margin: 40px 0;
  }
  &.o8-work-section-1 {
    padding: 50px 0;
  }
}

//Content
.o8-icon-grid {
  .o8-tech-icon {
    margin: 20px 20px;
  }
}

.o8-icon-grid .fab {
  font-size: 125px;
}

.o8-icon-grid img {
  height: 100px;
}

.o8-section-bck-img-wrapper {
  position: relative;
  overflow: hidden;
}

.o8-section-background-image {
  position: absolute;
  top: 300px;
  left: -55px;
  z-index: -100;
  mix-blend-mode: multiply;
  opacity: 0.75;
}

.o8-theme.o8-page-background-image {
  background: url("/assets/img/apollo-fade-sketch.png") no-repeat fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  background-blend-mode: multiply;
}

.o8-animated-window {
  padding: 0;
  position: relative;
  overflow: hidden;
  border: 10px solid $basic-black;
  border-radius: 22px;
  height: 425px;
  width: 225px;
  object-fit: cover;
  margin-left: 260px;
  background-image: url(../assets/img/articles/launch.jpg);
  background-position: center;
  color: white;
  font-family: "Muli", sans-serif;
  margin-bottom: 120px;
  img,
  video,
  div,
  lottie-player {
   
  }
}

.fade {
  animation-name: fade;
  animation-duration: 1s;
}

@keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}

.o8-animated-window {
  p {
      height: 425px;
      display: table-cell;
      vertical-align: middle;
      text-align: left;
      padding: 14px;
      font-size: 18px;
      font-weight: 100;
      font-style: italic;
      .job-title {
        font-size: 16px;
        font-weight: 800;
        font-style: normal;
        width: 100%;
        text-align: left;
        display: block;
      }
      .industry {
        font-size: 12px;
      }
  }

}

.work-testimonial {
  font-style: italic;
  .job-title {
    font-style: normal;
    font-weight: 800;
  }
  .industry {
    font-weight: 100;
    font-size: 14px;
  }
}

pre.o8-text-background {
  background: none;
  border: none;
  z-index: -1000;
  position: absolute;
  top: 20px;
  font-size: 9px;
  list-style: none;
  max-width: 375px;
  overflow: hidden;
  margin-top: 20px;
  margin-left: 80px;
  opacity: 0.6;
  &.o8-first {
    left: -2%;
  }
  &.o8-last {
    right: -2%;
    z-index: -100;
  }
  code {
    background: none;
    color: $secondary-color-1;
  }
}

.o8-page-center-small-description {
  padding: 0 10px;
  .fas {
    font-size: 60px;
    padding: 20px 0 40px 0;
    color: $primary-color-2;
  }
  img {
    padding: 30px 0 40px 0;
    height: 140px;
  }
  h3 {
    height: 50px;
  }
  a {
    color: white;
    text-decoration: underline;
  }
  ul {
    width: 180px;
    margin: 0 auto;
  }
}

.o8-client-image {
  width: 240px !important;
  height: 150px !important;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0 0 !important;
  img {
    width: 240px !important;
    height: 150px !important;
  }
}

.o8-team-member-image {
  p {
    font-size: 18px;
  }
}


.o8-page-section-grey {
  background-color: $secondary-color-3;
}

.o8-theme img.submit-gif {
  display: block;
  margin: 0 auto;
}

.o8-twin-panel {
  background-color: $primary-color-2;
  color: $basic-white;
  min-height: 395px;
  padding: 0;
}

.o8-theme .o8-twin-panel-alt {
  background-color: $background-page;
  color: $basic-black;
  .o8-twin-panel-container {
    padding: 20px 0px 40px 20px;
  }
}

.o8-theme .o8-twin-panel .o8-twin-panel-container {
  padding: 40px 20px 40px 20px;

  &.o8-case-study-preview {
    padding: 60px 0px 60px 60px;
    h4 {
      font-size: 20px !important;
      font-weight: 400;
      margin-bottom: 20px;
      &:hover {
        text-decoration: none !important;
      }
    }
    h2 {
      font-weight: 400;
      margin-bottom: 25px;
      &:hover {
        text-decoration: none !important;
      }
    }
    p {
      margin-bottom: 95px;
      font-weight: 600;
      font-size: 18px !important;
      &:hover {
        text-decoration: none !important;
      }
    }
    a {
      font-size: 18px !important;
      color: white !important;
      .fas {
        margin-left: 20px;
      }
      &:visited {
        color: white !important;
      }
      &:hover {
        text-decoration: none !important;
      }
    }
    .o8-twin-panel-img {
      margin: 0 0 !important;
    }
  }
}

.o8-twin-panel-image-container {
  padding: 0;
}

.o8-twin-panel-img {
  width: 100%;
  object-fit: contain;
  &.o8-xamarin-tablet {
    margin-top: -20px;
    margin-left: -20px;
  }
}

.o8-case-study {
  margin-top: 80px;
}

.o8-work-list {
  padding-top: 50px;
  padding-bottom: 50px;
}

.o8-case-study-heading {
  color: $primary-color-1;
  font-size: 24px !important;
  font-weight: 700 !important;
}

/*.o8-blog-list{
  background-color: transparent;
  min-height: 300px;
}

.o8-blog-list > div:first-child{ margin-right: 20px; }
.o8-blog-list > div:last-child{ margin-left: 20px; }*/

.o8-blog-share {
  i {
    padding: 2px;
  }
}

.o8-blog-item-container {
  background-color: $secondary-color-3;
  border-radius: 25px;
  min-height: 300px;
  padding: 40px;
  margin: 10px 0;
}

.o8-blog-promoted-item {
  background-color: $primary-color-2;
  border-radius: 0;
  color: $basic-white;
  background-size: cover;
  a,
  a:hover,
  a:focus {
    font-size: 16px;
    font-weight: 700;
    color: $basic-white;
  }
}

.color-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $primary-color-2;
  opacity: 0.6;
}

.o8-blog-tag-list,
.o8-tag-list {
  .o8-tag {
    background-color: $basic-white;
    border-radius: 5px;
    padding: 10px;
    margin-right: 10px;
    margin-top: 10px;
    color: $basic-black;
    label {
      padding: 0;
      margin: 0;
    }
  }
}

.o8-tag-list > .o8-tag {
  padding: 5px 10px;
  background-color: $secondary-color-4;
}

.o8-tag:hover {
  background-color: $secondary-color-3;
}

.o8-tag > *,
.o8-tag {
  &:hover {
    cursor: pointer;
  }
}

.o8-blog-tag-list > .o8-tag:first-child,
.o8-tag-list > .o8-tag:first-child {
  margin-right: 10px;
}

.o8-blog-tag-list > .o8-tag:last-child,
.o8-tag-list > .o8-tag:last-child {
  margin-left: 0px;
  margin-right: 0px;
}

.o8-work-item {
  min-width: 250px;
  padding: 5px;
  margin-bottom: 20px;
  transition: all 1s ease;
  &:nth-child(3n-1) {
    margin-left: 2%;
    margin-right: 2%;
  }
  .o8-work-item-footer {
    padding: 10px 0 5px 0;
    h3 {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 15px;
    }
  }
  &:hover {
    background-color: $primary-color-2;
    color: white;
    z-index: 9999;
    transform: translateY(-2px);
    cursor: pointer;
  }
  img {
    width: 100%;
  }
}

.o8-post-breadcrumb {
  padding: 10px 0;
  a {
    padding: 0 5px 0 0;
    text-align: center;
  }
  .fas {
    padding: 5px;
  }
  label {
    padding: 0 0 0 5px;
  }
}

.o8-work-main-img {
  max-height: 400px;
  width: 100%;
  object-fit: cover;
}

.o8-work-contact-section {
  background-color: $secondary-color-3;
  min-height: 350px;
  padding: 50px;
  margin: 0;
  img {
    mix-blend-mode: multiply;
  }
}

.o8-work-left-col {
  padding: 0 40px 0 0;
}

.o8-work-content > * {
  padding: 10px 0;
}

.o8-work-contact-content > * {
  padding: 10px 0;
}

.o8-work-contact-img {
  width: 40%;
}

.o8-work {
  ul {
    font-weight: 700;
    li {
      margin-bottom: 10px;
    }
  }
}

.o8-expertise-list {
  margin-left: 0;
  li {
    display: block;
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
    a {
      font-size: 20px;
    }
  }
}

.o8-twin-panel-sml-img {
  width: 50px;
}

.o8-tag-line {
  font-size: 36px;
}

.o8-team-photo-message {
  position: relative;
  top: -250px;
  margin-left: 10% !important;
  width: 50%;
}

.contact-form {
  label {
    font-weight: 700;
  }
}

.o8-theme .card {
  min-width: 250px;
  padding: 0;
  margin-bottom: 20px;
  &:nth-child(3n-1) {
    margin-left: 2%;
    margin-right: 2%;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
  }
  .card-body,
  img {
    min-height: 250px;
  }
  h2 {
    font-size: 22px;
    font-weight: 600;
  }
  .readmore {
    color: $primary-color-2;
    position: absolute;
    bottom: 5px;
  }
}

a.card-link {
  color: #212529;
}

.o8-blog-content-section .o8-blog-content {
  img {
    margin-bottom: 20px;
  }
  h1 {
    margin-bottom: 10;
  }

  h2 {
    font-size: 23px;
    line-height: 34px;
    font-weight: 700;
  }

  .byline {
    margin-top: 15px;
    img {
      float: left;
      height: 20px;
      margin-right: 10px;
      margin-top: 0px;
    }
    margin-bottom: 40px;
    font-size: 16px;
  }
  p {
    font-size: 19px;
    margin-bottom: 40px;
  }

  ul,
  ol {
    font-size: 19px;
    margin-bottom: 40px;
    li {
      margin-bottom: 15px;
      padding-left: 10px;
    }
  }

  .article-body {
    h2 {
      margin-top: 60px;
      margin-bottom: 40px;
      font-size: 23px;
      font-weight: 700;
    }
    h3 {
      font-weight: 700;
      font-size: 19px;
      margin-bottom: 20px;
      margin-top: 60px;
    }
    margin-top: 40px;
    margin-bottom: 60px;
  }
  a {
    text-decoration: underline;
  }
}

.o8-blog-content,
.o8-blog-sidebar {
  margin-top: 10px;
}

.o8-blog-sidebar {
  padding: 0 25px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
  }
  ul {
    list-style: none;
  }
}

.o8-blog-author {
  .o8-blog-img {
    padding: 0;
    border-radius: 100px;
    height: 60px;
    width: 60px;
    margin: 0 $spacer;
  }
  h5 {
    color: $primary-color-2;
  }
}

@media (max-width: 576px) {
  .o8-blog-content-section .o8-blog-content {
    border-right: 1px solid transparent;
  }
  .o8-blog-sidebar {
    padding: 0;
    margin-top: $o8-spacer;
  }
  .o8-blog-author {
    padding: $spacer 0;
  }
}

//footer
.o8-footer {
  background-color: $background;
  * {
    color: white;
  }
  .o8-footer-wrapper .footer-col-3 p {
    float: right;
  }
}

.o8-logo-footer {
  margin: 25px 0;
  width: 200px;
  height: 50px;
}

.contact-list li {
  padding: 10px 0;
}

.o8-media-list {
  list-style-type: none;
  li {
    color: white;
    padding: 0 10px;
    .fab {
      font-size: 25px;
    }
  }
}

.o8-media-list > li:last-child {
  padding: 0 0 0 10px;
}

@media (max-width: 991px) {
  .o8-animated-window {
    margin: 40px auto;
  }
}

//Tablets
@media (max-width: 768px) {
  .o8-animated-window .o8-last {
    display: none;
  }

  .o8-theme .o8-twin-panel .o8-twin-panel-container {
    padding: 40px 20px 40px 20px;

    &.o8-case-study-preview {
      padding: 20px 0px 20px 20px;
    }
  }

  .o8-page-center-small-description {
    h3 {
      margin-bottom: 40px;
    }
  }
}

//Mobile Phones
@media (max-width: 576px) {
  .o8-theme a.o8-primary-btn {
    width: 100%;
  }
  .site-title div {
    width: 250px;
  }

  .mb-xs-40 {
    margin-bottom: 40px !important;
  }

  .o8-section-background-image {
    top: 100px !important;
    left: -500px;
    opacity: 0.2;
    width: 1152px;
    max-width: none;
  }

  .break-lg {
    display: none;
  }

  .o8-team-photo-message {
    font-size: 14px !important;
    width: 75%;
  }

  .o8-home-section-1 {
    min-height: 420px !important;
  }


  .o8-home-branding-text {
    padding-top: 0 !important;
    margin-left: -50px !important;
    h1 {
      margin-top: 0;
    }
  }

  .o8-team-photo-message {
    top: -150px;
  }

  .o8-theme .o8-twin-panel .o8-twin-panel-container {
    padding: 40px 20px 40px 20px;

    &.o8-case-study-preview {
      padding: 20px 0px 20px 20px;
    }
  }

  .o8-page-section-small-top {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  .o8-page-center-small-description {
    h3 {
      margin-bottom: 0px !important;
    }
  }

  .o8-work-small-img {
    display: block;
    margin: 0 auto !important;
  }

  form {
    margin-left: 0 !important;
  }

  .o8-theme input.o8-input {
    width: 100%;
  }

  .o8-theme textarea.o8-input {
    width: 100% !important;
  }

  .o8-primary-btn {
    width: 100% !important;
  }

  .o8-home-branding-text {
    h1 {
      font-weight: 700;
    }
  }

  .o8-work-case-img {
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 40px;
  }
}

//xs mobile phones
@media (max-width: 320px) {
  .o8-home-branding-text {
    padding-top: 0 !important;
    margin-left: 0 !important;
    h1 {
      margin-top: 0;
    }
  }
}
